import { HeadingBlockInterface } from "../heading-block/types";
import { TextBlockInterface } from "../text-block/types";
import { MediaBlockInterface } from "../media-block/types";
import { CalloutBlockInterface } from "../callout-block/type";
import { CodeBlockInterface } from "../code-block/types";
import { EntryInterface } from "../../../types/entries";
import { ReactNode } from "react";
import { VideoBlockInterface } from "../video-block/types";
import { FrameBlockInterface } from "../frame-block/types";
import { ScriptBlockInterface } from "../script-block/types";

export enum ContentBlockHandle {
  CALLOUT = "calloutBlock",
  CODE = "codeBlock",
  HEADING = "headingBlock",
  MEDIA = "mediaBlock",
  SCRIPT = "scriptBlock",
  TEXT = "textBlock",
  VIDEO = "videoBlock",
  FRAME = "frameBlock",
}

export interface ContentBlockInterface {
  uid: string;
  typeHandle: ContentBlockHandle;
  gridColumn?: string;
}

export interface ContentLayoutThemeInterface {
  gridColumnDesktop: string;
}

export interface ContentStyledComponentInterface {
  gridColumn?: string;
  theme: ContentLayoutThemeInterface;
}

export type ContentBlock =
  | HeadingBlockInterface
  | TextBlockInterface
  | MediaBlockInterface
  | CalloutBlockInterface
  | CodeBlockInterface
  | ContentBlockInterface
  | VideoBlockInterface
  | FrameBlockInterface
  | ScriptBlockInterface;

export interface ContentLayoutInterface extends EntryInterface {
  contentEditorFields: ContentBlock[];
  children?: ReactNode;
  gridLess?: boolean;
}
