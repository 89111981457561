import React from "react";
import styled, { ThemeProvider, StyledComponent } from "styled-components";
import { Link } from "gatsby";
import {
  EntryFeaturedImageInterface,
  EntryInterface,
} from "../../types/entries";
import { EntryBlocksInterface } from "./types";
import { ViewportSize } from "../grid/content-blocks/container/types";

const EntryContainer = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;

  @media (min-width: ${ViewportSize.TABLET}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: ${ViewportSize.DESKTOP}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const StyledEntry: StyledComponent<"div", any> = styled.div`
  margin: 0 0 3rem;
`;

const StyledEntryTitle = styled.div`
  grid-column: post;
  margin: 1rem 0 0;
  line-height: 1;
  font-weight: bold;

  a {
    text-decoration: none;
    color: #333;
  }
`;

const StyledEntryTagWrapper = styled.div`
  font-size: 1rem;
  line-height: 1.4;
  grid-column: post;
  margin: 0.5rem 0;
`;

const StyledEntryThumb = styled.div`
  background-color: ${(props) => props.theme.featuredBackgroundColor ?? "#aaa"};
  font-weight: bold;
  text-transform: uppercase;
  font-family: var(--font-family-heading);
`;

const StyledEntryImage = styled.img`
  width: 100%;
  height: auto;
`;

const StyledEntryThumbLink = styled(Link)`
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  text-decoration: none;
  color: ${(props) => props.theme.featuredTextColor ?? "#fff"};
`;

const EntryThumb = ({
  featuredImage,
  featuredBackgroundColor,
  featuredTextColor,
  title,
  uri,
}: {
  featuredImage?: EntryFeaturedImageInterface[];
  featuredBackgroundColor?: string;
  featuredTextColor?: string;
  title: string;
  uri: string;
}) => {
  let image = null;

  if (featuredImage && featuredImage.length) {
    image = featuredImage[0];
  }
  return (
    <ThemeProvider theme={{ featuredBackgroundColor, featuredTextColor }}>
      <StyledEntryThumb>
        <StyledEntryThumbLink to={`/${uri}`}>
          {image && (
            <StyledEntryImage
              src={image.large}
              alt={image?.mediaAltText ?? `Thumbnail for ${title}`}
            />
          )}
        </StyledEntryThumbLink>
      </StyledEntryThumb>
    </ThemeProvider>
  );
};

const buildEntryBlockLinks = (items: EntryInterface[]) =>
  items.map((item: EntryInterface) => {
    const {
      title,
      uri,
      uid,
      featuredBackgroundColor,
      featuredImage,
      entryTags,
    } = item;

    return (
      <StyledEntry key={uid}>
        <EntryThumb
          uri={uri}
          title={title}
          featuredImage={featuredImage}
          featuredBackgroundColor={featuredBackgroundColor}
        />
        <StyledEntryTitle>
          <Link to={`/${uri}`}>{title}</Link>
        </StyledEntryTitle>
        <StyledEntryTagWrapper>
          {entryTags.map((tag) => tag.title).join(", ")}
        </StyledEntryTagWrapper>
      </StyledEntry>
    );
  });

export const EntryBlocks: React.FunctionComponent<EntryBlocksInterface> = (
  props: EntryBlocksInterface
) => {
  const { entries } = props;
  const articleLinks = buildEntryBlockLinks(entries);

  return <EntryContainer>{articleLinks}</EntryContainer>;
};
