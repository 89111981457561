export const readableDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

interface DateSegments {
  month: string;
  day: string;
  year: string;
}

export const readableDateSegments = (dateString: string): DateSegments => {
  const date = new Date(dateString);
  const month = date.toLocaleDateString("en-US", {
    month: "short",
  });
  const day = date.toLocaleDateString("en-US", {
    day: "numeric",
  });
  const year = date.toLocaleDateString("en-US", {
    year: "numeric",
  });

  return {
    month,
    day,
    year,
  };
};
