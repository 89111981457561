import React, { FunctionComponent } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { TagInterface } from "./types";

const StyledTag = styled.span`
  font-size: 1rem;
  background: #f8f8f8;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  color: #666;
  padding: 0.5rem;
  display: inline-block;
  line-height: 1;
`;

const StyledTagLink = styled(Link)`
  text-decoration: none;
  color: #666;
`;

export const Tag: FunctionComponent<TagInterface> = (props: TagInterface) => {
  const { children, url } = props;
  return (
    <>
      {url ? (
        <StyledTagLink to={url}>
          <StyledTag>{children}</StyledTag>
        </StyledTagLink>
      ) : (
        <StyledTag>{children}</StyledTag>
      )}
    </>
  );
};
